import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Logo from "../components/Logo"

const AboutPage = () => {
  const { HcpcLogo, CrestLogo } = useStaticQuery(graphql`
    query AboutPageQuery {
      HcpcLogo: file(relativePath: { eq: "logos/hcpc.png" }) {
        childImageSharp {
          gatsbyImageData(width: 150)
        }
      }
      CrestLogo: file(relativePath: { eq: "logos/crest_csp.png" }) {
        childImageSharp {
          gatsbyImageData(width: 150)
        }
      }
    }
  `)

  return (
    <main>
      <Seo
        title="About me | Professional, knowledgeable, understanding."
        desc="I have a passion for pain relief and body optimization. I want you to feel the best you can. You deserve it after all. I have a lifetime of sports and injury experience. I am fully qualified sports massage therapist registered with the HCPC."
      />

      <div className="relative overflow-hidden">
        <div className="absolute w-full about-bg-h" style={{ height: "726px" }}>
          <StaticImage
            src="../assets/images/hero/about-me-2024.jpg"
            layout="fullWidth"
            loading="eager"
            objectPosition="50% 40%"
            width={800}
            breakpoints={[750, 1080, 1366, 1920]}
            placeholder="blurred"
            alt=""
            style={{
              position: "absolute",
              bottom: 0,
              top: 0,
              width: "100vw",
            }}
          />

          <div className="h-overlay-80"></div>

          <div
            className="absolute left-0 bottom-0 right-0"
            style={{ height: "300px", overflow: "hidden" }}
          >
            <svg
              viewBox="0 0 500 150"
              preserveAspectRatio="none"
              style={{ height: "100%", width: "100%" }}
            >
              <path
                d="M-1.12,131.73 C201.46,117.92 316.03,127.78 500.56,104.11 L500.00,150.00 L0.00,150.00 Z"
                style={{ stroke: "none", fill: "#ffffff" }}
                className="lg:hidden"
              ></path>
              <path
                d="M-27.09,119.89 C235.32,-6.41 339.16,290.63 569.98,-53.78 L500.00,150.00 L0.00,150.00 Z"
                style={{ stroke: "none", fill: "#ffffff" }}
                className="hidden lg:block"
              ></path>
            </svg>
          </div>
        </div>

        <div className="container">
          <div className="pt-16 lg:pt-44 grid grid-cols-about gap-4">
            <div className="z-10">
              <p className="font-header font-semibold text-b-blue-light">
                About me
              </p>
              <h1 className="tracking-tight font-header font-semibold text-white text-3xl md:text-4xl">
                My story
              </h1>
            </div>
            <div className="mt-6 lg:mt-16 z-10">
              <p className="pb-8 text-white leading-7">
                I have a life time of experience of various sports including
                rock climbing, running, athletics, weight lifting, MMA and yoga.
                I also understand how intrinsically linked your physical
                wellbeing is to your mental health. An athlete’s entire view of
                themselves can change once injured and no longer being able to
                perform how they once did. I have been through it myself and
                understand the pitfalls which will stop you regaining the
                performance you desire. After successfully rehabilitating myself
                I am confident and passionate about helping you get back to
                being your best.
              </p>

              <figure className="mb-8 text-center">
                <StaticImage
                  src="../assets/images/about-me-climbing.jpg"
                  layout="fullWidth"
                  width={800}
                  quality={90}
                  title="Carl Donnelly, Founder and registered Physiotherapist"
                  alt="Carl Donnelly, founder and registered physiotherapist, climbing a rock outdoors"
                />
                <figcaption className="mt-2 text-sm text-gray-600">
                  Carl Donnelly, Founder and registered Physiotherapist
                </figcaption>
              </figure>

              <p className="pb-8 leading-7">
                I am a registered and chartered physiotherapist graduating from
                the University of Sunderland in 2022. I started my business as a
                part time sports massage business in in 2020 after gaining my
                level 3 diploma in Sports Massage from Active IQ.
              </p>

              <div className="pb-16 px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
                  <div className="flex justify-center">
                    <Logo
                      image={HcpcLogo}
                      title="Health Care Professions Council (HCPC)"
                      alt="Health Care Professions Council (HCPC)"
                    />
                  </div>
                  <div className="flex justify-center">
                    <Logo
                      image={CrestLogo}
                      title="Chartered Society of Physiotherapy (CSP)"
                      alt="Chartered Society of Physiotherapy (CSP)"
                    />
                  </div>
                </div>
              </div>

              <p className="pb-16 leading-7">
                I am registered with the following professional bodies: the
                Health Care Professions Council (HCPC), the Chartered Society of
                Physiotherapists (CSP) and many health insurances will allow you
                to claim the cost of your treatment back. I also have an up to
                date Disclosure and Barring Service (DBS) clearance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default AboutPage
